import './css/main.css';
import barba from '@barba/core';

import { gsap } from "gsap";

import { defineCustomElements } from 'vidstack/elements';
import 'vidstack/styles/base.css';
import 'vidstack/styles/ui/buttons.css';
import 'vidstack/styles/ui/buffering.css';
import 'vidstack/styles/ui/captions.css';
import 'vidstack/styles/ui/tooltips.css';
import 'vidstack/styles/ui/live.css';
import 'vidstack/styles/ui/sliders.css';
import 'vidstack/styles/ui/menus.css';




barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });
        
        
      },
      once(data) {
        splashText(data);
      },
      afterOnce(data) {
        // menuHighlight(data);
        mainContentCheck();
        defineCustomElements();
        projectsGrid();
        importJSON();
        importCats();
        galleryGo();
        tagCheck();
        youAreReading();
      }
    }],
});

function mainContentCheck() {
  let mainContent = document.getElementsByClassName('mainContent');
  if (mainContent.length > 0) {
    import(/* webpackChunkName: "markdownProcess" */ "./js/components/markdownProcess.js").then(module => {
      const contentGo = module.default;
      contentGo();
    });
  };
};

function youAreReading() {
  let reading = document.getElementsByClassName('projectHeaderBar');
  if (reading.length > 0) {
    import(/* webpackChunkName: "youAreReading" */ "./js/components/headerOpen.js").then(module => {
      const readingGo = module.default;
      readingGo();
    });
  }
}

function splashText(data) {
  if (data.next.namespace == 'home') {
    import(/* webpackChunkName: "splashText" */ "./js/components/splashText.js").then(module => {
      const splashGo = module.default;
      splashGo();
    });
  } else {
    let splasher = document.getElementById('splash')
    splasher.classList.remove('transition-opacity');
    splasher.classList.add('hidden');
  };
};

function projectsGrid() {
  let projects = document.getElementsByClassName('projects');
  if (projects.length > 0) {
    import(/* webpackChunkName: "projectGrid" */ "./js/components/projectGrid.js").then(module => {
      const gridGo = module.default;
      gridGo();
    });
  }
}

function importJSON() {
  let json = document.getElementsByClassName('inputJson');
  if (json.length > 0) {
    import(/* webpackChunkName: "importJson" */ "./js/components/importJson.js").then(module => {
      const importJsonGo = module.default;
      importJsonGo();
    });
  }
}

function importCats() {
  let cats = document.getElementsByClassName('inputCats');
  if (cats.length > 0) {
    import(/* webpackChunkName: "importJson" */ "./js/components/importCats.js").then(module => {
      const importCats = module.default;
      importCats();
    });
  }
}

function bgColour(data) {
  import(/* webpackChunkName: "bgColour" */ "./js/components/bgColour.js").then(module => {
    const changeColour = module.default;
    changeColour(data);
  });
}

function galleryGo() {
  let gallery = document.getElementsByClassName('gallery');
  if (gallery.length > 0) {
    import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
      const galleryGo = module.default;
      galleryGo();
    });
  }
}

function hamburgerToggle(preset) {
  import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
    const toggle = module.default;
    toggle(preset);
  });
}

function tagCheck() {
  let tagCloud = document.getElementsByClassName('tag-cloud');
  if (tagCloud.length > 0) {
    import(/* webpackChunkName: "drawTagCloud" */ "./js/components/drawTagCloud.js").then(module => {
      const tagit = module.default;
      let tagData = document.getElementById('tagData').getAttribute('data-tagData');
      tagit(tagData);
    });
  }
}

let menuOpener = document.getElementById("menuOpener");
menuOpener.addEventListener("click", function() {hamburgerToggle()});

function scrollToTop() {
  // window.scrollTo({top: 0, behavior: 'smooth'});
  
};

function menuHighlight(data) {
  let home = document.getElementById('menuHome');
  let proj = document.getElementById('menuProjects');
  let tags = document.getElementById('menuTags');
  let bio = document.getElementById('menuBio');
  let cv = document.getElementById('menuCv');
  let contact = document.getElementById('menuContact');
  let search = document.getElementById('menuSearch');
  if (data.next.namespace === 'home') {
    // home.classList.add('!text-marcusBlue');
    proj.classList.add('!text-marcusBlue');
    tags.classList.remove('!text-marcusBlue');
    bio.classList.remove('!text-marcusBlue');
    cv.classList.remove('!text-marcusBlue');
    contact.classList.remove('!text-marcusBlue');
    search.classList.remove('!text-marcusBlue');
  } else if (data.next.namespace === 'projects') {
    // home.classList.remove('!text-marcusBlue');
    proj.classList.add('!text-marcusBlue');
    tags.classList.remove('!text-marcusBlue');
    bio.classList.remove('!text-marcusBlue');
    cv.classList.remove('!text-marcusBlue');
    contact.classList.remove('!text-marcusBlue');
    search.classList.remove('!text-marcusBlue');
  } else if (data.next.namespace === 'tags') {
    // home.classList.remove('!text-marcusBlue');
    proj.classList.remove('!text-marcusBlue');
    tags.classList.add('!text-marcusBlue');
    bio.classList.remove('!text-marcusBlue');
    cv.classList.remove('!text-marcusBlue');
    contact.classList.remove('!text-marcusBlue');
    search.classList.remove('!text-marcusBlue');
  } else if (data.next.namespace === 'bio') {
    // home.classList.remove('!text-marcusBlue');
    proj.classList.remove('!text-marcusBlue');
    tags.classList.remove('!text-marcusBlue');
    bio.classList.add('!text-marcusBlue');
    cv.classList.remove('!text-marcusBlue');
    contact.classList.remove('!text-marcusBlue');
    search.classList.remove('!text-marcusBlue');
  } else if (data.next.namespace === 'cv') {
    // home.classList.remove('!text-marcusBlue');
    proj.classList.remove('!text-marcusBlue');
    tags.classList.remove('!text-marcusBlue');
    bio.classList.remove('!text-marcusBlue');
    cv.classList.add('!text-marcusBlue');
    contact.classList.remove('!text-marcusBlue');
    search.classList.remove('!text-marcusBlue');
  } else if (data.next.namespace === 'contact') {
    // home.classList.remove('!text-marcusBlue');
    proj.classList.remove('!text-marcusBlue');
    tags.classList.remove('!text-marcusBlue');
    bio.classList.remove('!text-marcusBlue');
    cv.classList.remove('!text-marcusBlue');
    contact.classList.add('!text-marcusBlue');
    search.classList.remove('!text-marcusBlue');
  } else if (data.next.namespace === 'search') {
    // home.classList.remove('!text-marcusBlue');
    proj.classList.remove('!text-marcusBlue');
    tags.classList.remove('!text-marcusBlue');
    bio.classList.remove('!text-marcusBlue');
    cv.classList.remove('!text-marcusBlue');
    contact.classList.remove('!text-marcusBlue');
    search.classList.add('!text-marcusBlue');
  } else {
    // home.classList.remove('!text-marcusBlue');x
    proj.classList.remove('!text-marcusBlue');
    tags.classList.remove('!text-marcusBlue');
    bio.classList.remove('!text-marcusBlue');
    cv.classList.remove('!text-marcusBlue');
    contact.classList.remove('!text-marcusBlue');
    search.classList.remove('!text-marcusBlue');
  }
}

function debounce(callback, wait) {
  let timeout;
  return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}

window.addEventListener('resize', debounce(function() {tagCheck()}, 1000))

barba.hooks.beforeEnter((data) => {
  
  bgColour(data);
});

barba.hooks.afterLeave((data) => {
  hamburgerToggle('c');
  window.scrollTo({top: 0, behavior: "instant"});
});

barba.hooks.after((data) => {
  // menuHighlight(data);
  mainContentCheck();
  projectsGrid();
  importJSON();
  importCats();
  galleryGo();
  defineCustomElements();
  tagCheck();
  youAreReading();
});

barba.hooks.before((data) => {
  
})